import 'moment/locale/sv'

import * as moment from 'moment'

import { Col, Grid } from '../components/styled/layout'
import { StaticQuery, graphql } from 'gatsby'

import { Heading } from '../components/styled/typography'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Truncate from 'react-truncate'
import posed from 'react-pose'
import styled from 'styled-components'

const LatestMovieWrapper = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  color: white;
  border-radius: 15px;

  & h2 > span {
    color: #ffffff70;
  }
  @media screen and (max-width: 980px) {
    & {
      grid-template-columns: 1fr;
    }
  }
`
const LatestMovieMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3vmax;
`

const Poster = styled.div`
  & .gatsby-image-wrapper {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 3px 6px 40px #00000080;
  }

  @media screen and (max-width: 980px) {
    & {
      margin: 0px;
    }
  }
`

const LatestMovie = ({ numStars }) => {
  return (
    <StaticQuery
      query={graphql`
        query LatestMovie {
          allAirtable(limit: 1, sort: { fields: [data___Datum], order: DESC }) {
            edges {
              node {
                data {
                  Film
                  Path
                  Datum
                  Janet_tyckte
                  Janets_betyg
                  Lisa_tyckte
                  Lisas_betyg
                  Filmbild {
                    localFiles {
                      childImageSharp {
                        fluid(
                          maxWidth: 400

                          cropFocus: CENTER
                        ) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const movie = data.allAirtable.edges[0].node.data
        const poster = movie.Filmbild.localFiles[0].childImageSharp.fluid

        return (
          <LatestMovieWrapper>
            <Col>
              <Poster>
                <Link to={movie.Path}>
                  <Img fluid={poster} />
                </Link>
              </Poster>
            </Col>
            <LatestMovieMeta>
              <Link to={movie.Path}>
                <Heading className="latest">
                  <span>Senaste</span>
                  {movie.Film}{' '}
                  <span>
                    {moment(movie.Datum)
                      .locale('sv')
                      .fromNow()}
                  </span>
                </Heading>
              </Link>
              <Grid cols="1fr 1fr">
                <Col>
                  <strong>Janet tyckte:</strong> <br />
                  <p>
                    <Truncate lines={3}>{movie.Janet_tyckte}</Truncate>
                  </p>
                  <strong>Betyg: </strong>
                  <br />
                  {numStars(movie.Janets_betyg)}
                </Col>
                <Col>
                  <strong>Lisa tyckte:</strong> <br />
                  <p>
                    <Truncate lines={3}>{movie.Lisa_tyckte}</Truncate>
                  </p>
                  <strong>Betyg: </strong>
                  <br />
                  {numStars(movie.Lisas_betyg)}
                </Col>
              </Grid>
            </LatestMovieMeta>
          </LatestMovieWrapper>
        )
      }}
    />
  )
}

export default LatestMovie
