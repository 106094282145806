import React, { Component } from 'react'
import { graphql } from 'gatsby'
import META from '../components/META'
import styled from 'styled-components'

import Layout from '../components/layout'

import LatestMovie from '../components/LatestMovie'
import Movies from '../components/Movies'

import StarIcon from '../assets/icons/baseline-star-24px.svg'

import { Sec, Wrap, Grid, Col } from '../components/styled/layout'
import { Heading } from '../components/styled/typography'

const SearchInput = styled.input`
  width: 100%;
  padding: 20px 30px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 3px 30px #00000010;
  outline: none;
`

class IndexPage extends Component {
  state = {
    mounted: false,
    search: '',
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    })
  }
  searchInputChange = this.handleSearchInputChange.bind(this)
  handleSearchInputChange(e) {
    const { value } = e.target
    const state = this.state
    state.search = value
    this.setState({
      ...state,
    })
  }

  calcTotalRating = (movies, person) => {
    return movies
      .map(movie => movie.node.data[person + '_betyg'])
      .reduce((total, betyg) => total + betyg)
  }
  numStars = rating => {
    const ratingArray = []

    for (var i = 1; i <= rating; i++) {
      ratingArray.push(<StarIcon />)
    }
    return ratingArray.map((item, i) => <span key={i}>{item}</span>)
  }
  render() {
    const movies = this.props.data.allAirtable.edges
    const filteredMovies = movies.filter(item => {
      return (
        item.node.data.Film.toLowerCase().indexOf(
          this.state.search.toLowerCase()
        ) !== -1
      )
    })

    const janetTotBetyg = this.calcTotalRating(movies, 'Janets')
    const lisaTotBetyg = this.calcTotalRating(movies, 'Lisas')

    const highestRater = () => {
      if (janetTotBetyg > lisaTotBetyg) {
        return 'Janet'
      } else {
        return 'Lisa'
      }
    }

    return (
      <>
        <META />
        <Layout>
          <Sec bg="#2C0000">
            <Wrap>
              <Grid>
                <LatestMovie numStars={this.numStars} />
              </Grid>
            </Wrap>
          </Sec>
          <Sec space="40px 0px">
            <Wrap>
              <SearchInput
                onChange={this.searchInputChange}
                type="text"
                placeholder="Sök bland filmerna"
              />
            </Wrap>
          </Sec>
          <Sec>
            <Wrap>
              <Grid>
                <Col>
                  <Heading>
                    Alla filmer{' '}
                    <span>
                      Visar {filteredMovies.length} av {movies.length} filmer.
                    </span>
                  </Heading>
                </Col>
              </Grid>
              <Grid>
                <Col>
                  <Movies
                    search={this.state.search}
                    numStars={this.numStars}
                    movies={filteredMovies}
                    isMounted={this.state.mounted}
                  />
                </Col>
              </Grid>
            </Wrap>
          </Sec>
        </Layout>
      </>
    )
  }
}

export default IndexPage

export const query = graphql`
  query AllMoviesQuery {
    allAirtable(limit: 99, sort: { fields: [data___Datum], order: DESC }) {
      edges {
        node {
          id
          data {
            Path
            Film
            gemBetyg
            Janets_betyg
            Lisas_betyg
            Datum
            Filmbild {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
