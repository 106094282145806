import React from 'react'
import * as moment from 'moment'
import 'moment/locale/sv'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'

import posed, { PoseGroup } from 'react-pose'

const MoviesContainer = styled(posed.div({}))`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2vmax;
  min-height: 300px;
  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`

const Movie = styled(
  posed.div({
    enter: {
      opacity: 1,
      y: 0,
    },
    exit: { opacity: 0, y: 50 },
  })
)`
  display: grid;
  grid-template-columns: 150px auto;
`
const Poster = styled.div`
  & .gatsby-image-wrapper {
    height: 220px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 3px 6px 20px #00000020;
  }
`
const MovieMeta = styled.div`
  padding: 30px;
  & h3 {
    font-size: 1rem;
    word-break: break-word;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 1.5rem;
  }
  & > span {
    color: #00000070;
    display: block;
    font-size: 0.9rem;
  }
  & p.rating {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    color: #000000;
    & span {
      font-size: 1.2rem;
    }
  }
  ${Movie}:hover & h3 {
    text-decoration: underline;
  }
`

const Movies = ({ isMounted, movies, numStars }) => {
  const movieNum = index => {
    const moviesTotal = movies.length
    return moviesTotal - index
  }
  const wasSeen = date => {
    return moment(date)
      .locale('sv')
      .fromNow()
  }
  return (
    <MoviesContainer pose={isMounted ? 'mounted' : 'unMounted'}>
      <PoseGroup animateOnMount={true}>
        {movies.map(({ node: movie }, i) => (
          <Movie key={movie.id}>
            <Link to={movie.data.Path}>
              <Poster>
                <Img
                  fluid={
                    movie.data.Filmbild.localFiles[0].childImageSharp.fluid
                  }
                />
              </Poster>
            </Link>
            <Link to={movie.data.Path}>
              <MovieMeta>
                <h3>{movie.data.Film}</h3>
                <span>{wasSeen(movie.data.Datum)}</span>
                <p className="rating">{numStars(movie.data.gemBetyg)} </p>
              </MovieMeta>
            </Link>
          </Movie>
        ))}
      </PoseGroup>
    </MoviesContainer>
  )
}

export default Movies
